
import { useEffect, useState } from 'react';
import './App.css';
import WebsiteHomepage from './WebsiteHomepag';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfUse from './TermsOfUs';
import { HelmetProvider } from 'react-helmet-async';
import SEO from './SEO';
import SchemaMarkup from './SchemaMarkup';
import ScrollToTopButton from './ScrollToTopButton';
import { getHeroData } from './services/Hero';
import CookieConsent from 'react-cookie-consent';
import 'react-toastify/dist/ReactToastify.css';
function App() {
  const [seoData, setSeo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const fetchData = async () => {
    try {
      setLoading(true);
      const [heroResponse] = await Promise.all([getHeroData()]);
      setSeo(heroResponse?.hero[10]);
    } catch (error) {
      setError(error);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <HelmetProvider>
      <SchemaMarkup />
      <CookieConsent
        location="bottom"
        buttonText="Accept all cookies"
        declineButtonText="Decline cookies"
        enableDeclineButton
        cookieName="userConsent"
        style={{
          background: '#fff',
          color: '#222',
          borderRadius: '10px',
          width: '90%', // Responsive width
          maxWidth: '400px', // Limits max size on large screens
          margin: '0 auto',
          padding: '15px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Subtle shadow
          display: 'flex',
          flexWrap: 'wrap', // Ensures proper layout on small screens
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center', // Centers text on mobile
          marginBottom: '10px',
          marginLeft: '10px',
        }}
        buttonWrapperClasses="cookie-buttons-container" // Custom wrapper to control button order
        buttonStyle={{
          color: '#fff',
          background: '#222',
          fontSize: '14px',
          padding: '10px 18px',
          borderRadius: '5px',
          margin: '5px',
          flex: '1',
          minWidth: '120px',
        }}
        declineButtonStyle={{
          color: '#fff',
          background: '#222',
          fontSize: '14px',
          padding: '10px 18px',
          borderRadius: '5px',
          margin: '5px',
          flex: '1',
          minWidth: '120px',
        }}
        expires={30}
      >
        <div
          style={{
            fontSize: '16px',
            fontWeight: 'bold',
            marginBottom: '10px',
            width: '100%', // Ensures header spans full width
          }}
        >
          🍪 We use cookies!
        </div>
        <p
          style={{
            fontSize: '14px',
            margin: '5px 0',
            width: '100%', // Text should span full width
          }}
        >
          This website uses cookies to help us give you the best experience when
          you visit. By using this website, you consent to our use of these
          cookies.
        </p>
        {/* Custom styling to ensure Accept is first */}
        <style>
          {`
          .cookie-buttons-container {
            display: flex;
            flex-direction: column-reverse; /* This ensures Accept appears first */
            justify-content: center;
            width: 100%;
          }
        `}
        </style>
      </CookieConsent>

      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <SEO
                  title={seoData?.metaTitle}
                  description={seoData?.metaDescription}
                  keywords={seoData?.keywords}
                />
                <WebsiteHomepage />
              </>
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <>
                <SEO
                  title={seoData?.metaRobots}
                  description={seoData?.metaDescription}
                  keywords={seoData?.keywords}
                />
                <PrivacyPolicy />
                <ScrollToTopButton />
              </>
            }
          />
          <Route
            path="/terms-of-use"
            element={
              <>
                <SEO
                  title={seoData?.metaRobots}
                  description={seoData?.metaDescription}
                  keywords={seoData?.keywords}
                />
                <TermsOfUse />
                <ScrollToTopButton />
              </>
            }
          />
        </Routes>
      </Router>
    </HelmetProvider>
  );
}

export default App;
