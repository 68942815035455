import api from './Api';

function extractor(res) {
  return res.data.data.attributes;
}

export async function getHeroData() {
  const response = await api.get(
    `/api/ish-homepage?populate[hero][populate]=*`,
  );

  if (response.status !== 200) {
    // This will activate the closest `error.js` Error Boundary
    throw new Error('Failed to getHeroData');
  }

  return extractor(response);
}

export async function getCardData() {
  const response = await api.get(
    `/api/ish-homepage?populate[hero][populate][card][populate]=*`,
  );

  if (response.status !== 200) {
    // This will activate the closest `error.js` Error Boundary
    throw new Error('Failed to getNavbarData');
  }

  return extractor(response);
}

export async function getContactUs() {
  const response = await api.get(
    `/api/ish-homepage?populate[hero][populate][headOffice][populate][metaIcon][populate]=*`,
  );

  if (response.status !== 200) {
    // This will activate the closest `error.js` Error Boundary
    throw new Error('Failed to getContactUs');
  }

  return extractor(response);
}


