import React from 'react';
import PropTypes from 'prop-types';
import { API_URL } from './services/config';

const Hero = ({ data }) => {
  const hero_image = data.image.data.attributes
    ? `${API_URL}${data.image.data.attributes.url}`
    : '';

  return (
    <div
      id="heroSection"
      className="fixed top-0 left-0 w-full h-screen flex justify-center items-center z-0"
    >
      <div className="relative  w-full h-full overflow-hidden">
        <video
          className="absolute top-0 left-0 w-full h-full object-cover"
          autoPlay
          loop
          muted
        >
          <source src={hero_image} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      <div className="absolute top-[55%] md:top-[50%] text-center  text-white text-shadow-custom-hero max-w-8xl  px-4">
        <div className="headerBg ">
          <span className="block text-[26px] xs:text-[24px] sm:text-[28px] md:text-[36px] lg:text-[42px] xl:text-[46px]">
            {data.slogan2}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Hero;

Hero.propTypes = {
  data: PropTypes.object.isRequired,
};
