import Property1Default6 from './Property1Default6';
import PropTypes from 'prop-types';
import React from 'react';
import { API_URL } from './services/config';
import api from './services/Api';
import DOMPurify from 'dompurify';
import validator from 'validator';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import ReCAPTCHA from 'react-google-recaptcha-v3';
const ContactContainer = ({ className = '', data, contactData }) => {
  const [status, setStatus] = React.useState('');
  const [userIP, setUserIP] = React.useState('');
  const [errors, setErrors] = React.useState({});
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [recaptchaToken, setRecaptchaToken] = React.useState('');
  const [formData, setFormData] = React.useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
  });
  // Validate Input Fields
  const validateField = (name, value) => {
    let error = '';

    if (name === 'name' && value && !validator.isLength(value, { min: 2 })) {
      error = 'Name must be at least 2 characters long.';
    }

    if (name === 'email' && value && !validator.isEmail(value)) {
      error = 'Please enter a valid email address.';
    }

    if (name === 'phone' && value && !validator.isMobilePhone(value, 'any')) {
      error = 'Please enter a valid phone number.';
    }

    if (
      name === 'message' &&
      value &&
      validator.isEmpty(value, { ignore_whitespace: true })
    ) {
      error = 'Message cannot be empty.';
    }

    // Update errors state
    setErrors(prevErrors => ({ ...prevErrors, [name]: error }));
  };

  // Handle Input Change with Validation & Sanitization
  const handleChange = e => {
    const { name, value } = e.target;

    const sanitizedValue = DOMPurify.sanitize(value); // Sanitize input

    // Update form data
    setFormData(prev => ({ ...prev, [name]: sanitizedValue }));

    // Validate input and update errors
    validateField(name, sanitizedValue);
  };

  React.useEffect(() => {
    // Fetch the user's IP address
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => setUserIP(data.ip))
      .catch(error => console.error('Error fetching IP:', error));
  }, []);

  const handleSubmit = async e => {
    e.preventDefault();

    if (!executeRecaptcha) {
      console.error('reCAPTCHA not available');
      return;
    }

    // Get a fresh reCAPTCHA token
    const token = await executeRecaptcha('submit');
    setRecaptchaToken(token);

    if (!token) {
      alert(
        'reCAPTCHA verification failed. Please refresh the page and try again.',
      );
      return;
    }
    setStatus('Sending...');
    // Generate reCAPTCHA token before submitting
    // Check if any errors exist before submitting
    if (Object.values(errors).some(err => err)) {
      alert('Please fix the errors before submitting.');
      return;
    }

    try {
      await api
        .post(
          '/api/contact',
          {
            name: formData.name,
            email: formData.email,
            phone: formData.phone,
            subject: formData.subject,
            userIP: userIP,
            message: formData.message,
            recaptchaToken: token, // Send token to backend
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        .then(response => {
          alert('Email sent successfully!');
          setStatus('Your message has been sent successfully!');

          setFormData({
            name: '',
            email: '',
            phone: '',
            subject: '',
            message: '',
          });
        });
    } catch (error) {
      console.error(error);
      alert('Error sending email.');
      setStatus('Failed to send message. Please try again later.');
    }
  };

  return (
    <div
      className={`relative  flex   py-6  items-center justify-center  bg-white text-xs text-steelblue font-poppins ${className}`}
      data-scroll-to={data.href}
    >
      <div className="w-full max-w-7xl py-8 text-left">
        <div className="text-center mb-8">
          <div className="capitalize inline-block w-full max-w-xl mx-auto">
            <b className="text-[28px]   md:text-[36px]  lg:text-[36px] xl:text-[42px]">
              {data.title} <b>{data.subTitle}</b>
            </b>
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-center items-start space-y-8 md:space-y-0 px-2 ">
          <div className="bg-deepskyblue-100 w-full rounded-l-2xl  w-[80%] md:w-1/3 h-auto text-white ">
            <div className=" items-center justify-center px-4 py-8 lg:py-14  lg:px-8  ">
              <b className="text-xl inline-block mb-4">
                {data.headOffice.title}
              </b>
              {data.headOffice.metaIcon.map((icon, index) => {
                const icon_img = icon.image.data?.attributes
                  ? `${API_URL}${icon.image.data.attributes.url}`
                  : ``;
                return (
                  <div
                    key={index}
                    className="flex content-center items-center mb-4"
                  >
                    <div className=" flex items-center justify-center mr-4">
                      <img className="w-8 h-8" alt="" src={icon_img} />
                    </div>
                    <p className="m-0 text-[14px] ">
                      <b>{icon.title}</b> {icon.description}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex justify-center flex-col content-center  bg-white w-[90%] md:w-1/2 h-auto px-4   ">
            <b className="text-[18px] block ">{contactData.ishConForm.title}</b>
            <p className="mb-4 text-[16px]">
              {contactData.ishConForm.description}
            </p>
            <form className="space-y-2  font-poppins" onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                <div className="flex flex-col md:col-span-2   ">
                  <label className=" mb-1 font-medium">
                    {contactData.ishConForm.name} *
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className=" border-b border-[#0E7090] font-poppins focus:border-[#0E7090] focus:ring-[#0E7090]   focus:ring-1 focus:outline-none"
                    required
                  />
                  {errors.name && <p style={{ color: 'red' }}>{errors.name}</p>}
                </div>
                <div className="flex flex-col">
                  <label className="mb-1 font-medium">
                    {contactData.ishConForm.email} *
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="  border-b border-[#0E7090] font-poppins  focus:border-[#0E7090] focus:ring-[#0E7090]   focus:ring-1 focus:outline-none"
                    required
                  />
                  {errors.email && (
                    <p style={{ color: 'red' }}>{errors.email}</p>
                  )}
                </div>
                <div className="flex flex-col">
                  <label className="mb-1 font-medium">
                    {contactData.ishConForm.phone}
                  </label>
                  <input
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className="border-b border-[#0E7090]  focus:border-[#0E7090] focus:ring-[#0E7090] font-poppins   focus:ring-1 focus:outline-none"
                  />
                  {errors.phone && (
                    <p style={{ color: 'red' }}>{errors.phone}</p>
                  )}
                </div>
                <div className="flex flex-col md:col-span-2">
                  <label className="mb-1 font-medium">
                    {contactData.ishConForm.subject} *
                  </label>
                  <input
                    type="text"
                    name="subject"
                    value={formData.subject}
                    onChange={handleChange}
                    className="   border-b border-[#0E7090] font-poppins focus:border-[#0E7090] focus:ring-[#0E7090]   focus:ring-1 focus:outline-none"
                    required
                  />
                </div>
              </div>
              <div className="flex flex-col overflow-hidden h-32">
                <label className="mb-1 font-medium">
                  {contactData.ishConForm.massage} *
                </label>
                <textarea
                  type="text"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  className="  border-b font-poppins border-[#0E7090]  focus:border-[#0E7090] focus:ring-[#0E7090]   focus:ring-1 focus:outline-none"
                  rows="4"
                  required
                />
                {errors.message && (
                  <p style={{ color: 'red' }}>{errors.message}</p>
                )}
              </div>
              <div className="flex  justify-end space-x-4 py-4 align-center font-poppins gap-4">
                <button
                  onClick={() => {
                    setFormData({
                      name: '',
                      email: '',
                      phone: '',
                      subject: '',
                      message: '',
                    });
                    setErrors({
                      name: '',
                      email: '',
                      phone: '',
                      subject: '',
                      message: '',
                    });
                  }}
                  type="reset"
                  className="  bg-white text-steelblue text-[16px] hover:cursor-pointer"
                >
                  {contactData.ishConForm.btnName}
                </button>
                <button type="submit" className="bg-white hover:cursor-pointer">
                  {' '}
                  <Property1Default6
                    btnName={contactData.ishConForm.submitBtn}
                    property1DefaultPosition="relative"
                    property1DefaultWidth="150px"
                  />
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="flex justify-center text-center  mt-5 text-[14px] w-[85%]  md:w-[100%] content-center  m-auto">
          <p className="text-[18px] text-steelblue ">
            {contactData.description}
          </p>
        </div>
      </div>
    </div>
  );
};

ContactContainer.propTypes = {
  className: PropTypes.string,
};

export default ContactContainer;
