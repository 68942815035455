import { useEffect, useState } from 'react';
import { getHeroData } from './services/Hero';
import logo from './assets/favicon.svg';
const PrivacyPolicy = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const fetchData = async () => {
    try {
      setLoading(true);
      const [heroResponse] = await Promise.all([getHeroData()]);

      setData(heroResponse.hero[8]);
    } catch (error) {
      setError(error);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

return (
  <div className="min-h-screen flex flex-col items-center justify-center p-4 sm:p-6 w-full bg-gradient-to-r from-[#f5feff] via-[rgba(6,174,212,0.2)] to-[rgba(54, 96, 112, 0.2)]">
    {/* Logo */}
    <div className="w-full flex justify-center mb-4 sm:mb-6 md:mb-8">
      <a href="/">
        <img
          src={logo} // Replace with your logo path
          alt="Company Logo"
          className="h-12 sm:h-14 md:h-16 lg:h-20 xl:h-24 object-contain"
        />
      </a>
    </div>

    {/* Content Box */}
    <div className="px-4 sm:px-6 flex justify-center">
      <div className="w-full max-w-full sm:max-w-4xl md:max-w-6xl bg-white shadow-lg rounded-lg p-4 sm:p-6 md:p-8 lg:p-10 xl:p-12">
        <div
          className="text-sm sm:text-base md:text-lg leading-relaxed break-words overflow-wrap-break-word"
          dangerouslySetInnerHTML={{ __html: data?.description }}
        ></div>
      </div>
    </div>
  </div>
);
};

export default PrivacyPolicy;
