import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const Leadership = ({ className = '', data }) => {
  const [isFixed, setIsFixed] = useState(false);
  const leaderRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (!leaderRef.current) return;

      const heroSection = document.getElementById('heroSection');
      if (!heroSection) return;

      const heroBottom = heroSection.getBoundingClientRect().bottom;

      if (heroBottom <= 50) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div
      ref={leaderRef}
      className={`bg-white w-full py-8 text-center text-lg text-steelblue font-poppins transition-all duration-700 ease-in-out ${
        isFixed
          ? 'fixed top-0 left-0 w-full z-10 backdrop-blur-md shadow-lg'
          : 'relative mt-[90vh]'
      } ${className}`}
      data-scroll-to={data?.href || 'leadershipContainer'}
    >
      <div className="text-pretty max-w-7xl mx-auto pb-4 break-words whitespace-normal overflow-wrap text-[14px] md:text-[16px] lg:text-[18px] pt-8 text-center  leading-6 md:leading-[40px] md:leading-10 px-10">
        <div dangerouslySetInnerHTML={{ __html: data.description }}></div>
      </div>
    </div>
  );
};

Leadership.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object.isRequired,
};

export default Leadership;
