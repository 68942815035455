import { Helmet } from 'react-helmet-async';

function SchemaMarkup() {
  const schemaData = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    url: 'https://www.iseehear.com',
    name: 'Iseehear Inc. Life Sciences',
    contactPoint: {
      '@type': 'ContactPoint',
      telephone: '+1 416-492-4358',
      contactType: 'Customer service',
    },
  };
  const organizationSchema = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    url: 'https://www.iseehear.com',
    name: 'Iseehear Inc. Life Sciences',
    contactPoint: {
      '@type': 'ContactPoint',
      telephone: '+1 416-492-4358',
      contactType: 'Customer service',
    },
  };

  const itemListSchema = {
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        url: 'https://www.iseehear.com/softmouse+free+mouse+colony.php',
      },
      {
        '@type': 'ListItem',
        position: 2,
        url: 'https://www.iseehear.com/softmouse.php',
      },
    ],
  };
  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
      <script type="application/ld+json">
        {JSON.stringify(organizationSchema)}
      </script>
      <script type="application/ld+json">
        {JSON.stringify(itemListSchema)}
      </script>
    </Helmet>
  );
}

export default SchemaMarkup;
