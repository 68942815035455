import { Helmet } from 'react-helmet-async';

function SEO({ title, description, keywords }) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="robots" content="index, follow" />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
}

export default SEO;
