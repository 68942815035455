import React from 'react';
import { API_URL } from './services/config';

const OurStory = ({ data }) => {
  const about_img = data?.image.data[0].attributes
    ? `${API_URL}${data?.image.data[0].attributes.url}`
    : '';
  return (
    <div
      className="relative  py-10    bg-azure  text-center  w-full  overflow-hidden "
      data-scroll-to={data?.href || 'ourStoryContainer'}
    >
      <img
        className="absolute top-0 left-0 w-full h-full object-cover"
        alt=""
        src={about_img}
      />
      <div className="relative z-10  capitalize inline-block w-full max-w-xl mx-auto text-[28px]   md:text-[36px]  lg:text-[36px] xl:text-[42px]">
        <b>{data.title} </b> <b>{data.subTitle}</b>
      </div>
      <div className="relative z-10 flex justify-center mt-2 px-10 md:px-10 lg:px-16 text-left leading-8 ">
        <div className="text-[14px]  md:text-[16px] lg:text-[18px]  break-words whitespace-normal overflow-wrap text-pretty  font-medium inline-block w-full h-full max-w-7xl mx-auto">
          <div dangerouslySetInnerHTML={{ __html: data.description }}></div>
        </div>
      </div>
    </div>
  );
};
export default OurStory;
