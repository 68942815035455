import { useMemo } from "react";
import PropTypes from "prop-types";

const Property1Default5 = ({
  className = "",
  cAReers,
  property1DefaultBorder,
  property1DefaultPosition,
  property1DefaultTop,
  property1DefaultLeft,
  property1DefaultWidth,
}) => {
  const property1Default2Style = useMemo(() => {
    return {
      border: property1DefaultBorder,
      position: property1DefaultPosition,
      top: property1DefaultTop,
      left: property1DefaultLeft,
      width: property1DefaultWidth,
    };
  }, [
    property1DefaultBorder,
    property1DefaultPosition,
    property1DefaultTop,
    property1DefaultLeft,
    property1DefaultWidth,
  ]);

  return (
    <div
      className={`gradient-border rounded-8xs bg-white overflow-hidden flex flex-row items-center justify-center
         py-[3px] px-[15px] text-left text-xl text-steelblue font-poppins border-[4px] border-solid border-deepskyblue-100 ${className}`}
      style={property1Default2Style}
    >
      <div className="relative uppercase font-medium">{cAReers}</div>
    </div>
  );
};

Property1Default5.propTypes = {
  className: PropTypes.string,
  cAReers: PropTypes.string,

  /** Style props */
  property1DefaultBorder: PropTypes.any,
  property1DefaultPosition: PropTypes.any,
  property1DefaultTop: PropTypes.any,
  property1DefaultLeft: PropTypes.any,
  property1DefaultWidth: PropTypes.any,
};

export default Property1Default5;
