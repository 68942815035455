import { useCallback } from 'react';

const Footer = ({ data }) => {

  const onClick = useCallback(link => {
    window.open(link);
  }, []);

  return (
    <div className="relative bg-white w-full text-base text-white bg-gradient-to-b from-[rgba(6,174,212,0.15)] to-white">
      {/* <div className="container mx-auto py-8 text-center text-steelblue text-2xl md:text-3xl font-medium [text-shadow:1px_0_0_#fff,0_1px_0_#fff,-1px_0_0_#fff,0_-1px_0_#fff] lg:text-[32px] lg:text-left">
        {data?.title}
      </div> */}
      <div className="bg-deepskyblue-200 w-full py-4">
        <div className="container mx-auto flex flex-col md:flex-row items-center justify-between w-[90%]">
          <b className="text-center md:text-left mb-2 md:mb-0 md:text-[14px]">
            {data?.subTitle}
          </b>
          <div className="flex space-x-4 md:text-[14px]">
            {data.link.map((link, index) => (
              <b
                key={index}
                className="cursor-pointer"
                onClick={() => onClick(link.href)}
              >
                {link.label}
              </b>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
