import Card from './Card';
import PropTypes from 'prop-types';

const Solutions = ({ className = '', data, cardData }) => {
  return (
    <div
      className={`relative  pb-14  w-full min-h-screen 
        
        [background:linear-gradient(180deg,_#f5feff,_rgba(59,_44,_124,_1)_100%,_#06aed4)]
         w-full h-auto overflow-hidden text-center text-21xl text-steelblue font-poppins ${className}`}
      data-scroll-to={data?.href || 'portfolioContainer'}
    >
      <div className="flexflex-col lg:mt-[50px]   justify-center items-center text-center my-8 opacity-100">
        <b className=" text-[28px] text-steelblu  md:text-[36px]  lg:text-[36px] xl:text-[42px] capitalize ">
          {data?.title}
        </b>
      </div>
      <img
        className=" absolute top-[0] left-[-1px] w-full h-full object-cover"
        alt=""
        src="/scottwebboxhpds4wv8yunsplash-1@2x.svg"
      />

      {/* <div
        className="absolute lg:top-[95.4px] lg:left-[75px] md:top-[95.4px] md:left-[75px]  top-[30px] left-[35px] 
      shadow-[20px_20px_20px_rgba(8,_138,_178,_0.15)] rounded-8xs bg-darkslateblue-200 lg:w-[50.9px] lg:h-[50.9px] w-[20px] h-[20px] md:w-[30.9px] md:h-[30.9px] [transform:_rotate(-30deg)] [transform-origin:0_0]"
      /> */}
      {/* <div
        className="absolute top-[800.9px] left-[100.7px] shadow-[20px_20px_20px_rgba(8,_138,_178,_0.45)] rounded-8xs
       bg-darkslateblue-200 w-[57.2px] h-[57.2px] [transform:_rotate(21.8deg)] [transform-origin:0_0]"
      /> */}
      {/* <div className="absolute top-[100.8px] left-[1610.7px]   shadow-[20px_20px_20px_rgba(8,_138,_178,_0.1)] rounded-8xs bg-darkslateblue-200 w-[94.8px] h-[94.8px] [transform:_rotate(20.2deg)] [transform-origin:0_0]" /> */}

      <div className="container mx-auto max-w-8xl">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-6 ">
          {cardData?.map((card, index) => (
            <a
              href={card.href}
              key={index}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <Card
                key={index}
                title={card.title}
                description={card.discription}
                image={card?.image}
              />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

Solutions.propTypes = {
  className: PropTypes.string,
};

export default Solutions;
