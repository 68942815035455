import { useEffect, useState } from 'react';
import Solutions from './Portfolio';
import Leadership from './Leadership';
import ContactContainer from './ContactContainer';
import Hero from './Hero';
import Header from './Header';
import OurThought from './OurThought';
import JoinTeam from './JoinTeam';
import OurStory from './OurStory';
import Footer from './Footer';
import { getNavbarData } from './services/Header';
import { getHeroData, getContactUs, getCardData } from './services/Hero';
import { getFooterData } from './services/Footer';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import ScrollToTopButton from './ScrollToTopButton';
import { REACT_RECAPTCHA_SITE_KEY } from './services/config/index';
const WebsiteHomepage = () => {
  const [navbarData, setNavbarData] = useState(null);
  const [heroData, setHeroData] = useState(null);
  const [portfolioData, setPortfolioData] = useState(null);
  const [leadershipData, setLeadershipData] = useState(null);
  const [ourThoughtData, setOurThoughtData] = useState(null);
  const [joinTeamData, setJoinTeamData] = useState(null);
  const [aboutData, setAboutData] = useState(null);
  const [contactData, setContactData] = useState(null);
  const [contactFormData, setContactFormData] = useState({});
  const [partnersData, setPartnersData] = useState(null);
  const [footerData, setFooterData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [cardData, setCardData] = useState(null);

  const fetchData = async () => {
    try {
      setLoading(true);
      const [
        navbarResponse,
        heroResponse,
        contactUs,
        footerData,
        cardResponse,
      ] = await Promise.all([
        getNavbarData(),
        getHeroData(),
        getContactUs(),
        getFooterData(),
        getCardData(),
      ]);
      setNavbarData(navbarResponse.ishNavbar);
      setHeroData(heroResponse.hero[0]);
      setPortfolioData(heroResponse.hero[1]);
      setLeadershipData(heroResponse.hero[2]);
      setOurThoughtData(heroResponse.hero[3]);
      setJoinTeamData(heroResponse.hero[4]);
      setAboutData(heroResponse.hero[5]);
      setContactData(contactUs.hero[6]);
      setContactFormData(heroResponse.hero[6]);
      setPartnersData(heroResponse.hero[7]);

      setCardData(cardResponse.hero[1]?.card);
      setFooterData(footerData.ishFooter);
    } catch (error) {
      setError(error);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return (
      <div className="w-full bg-white text-steelblue font-poppins">
        <div className="flex justify-center items-center h-screen">
          <div className="lds-default">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return <div>Something went wrong: {error.message}</div>;
  }

  return (
    <div className="w-full bg-white text-steelblue font-poppins">
      {navbarData && <Header navbarData={navbarData} />}
      {heroData && <Hero data={heroData} />}
      {leadershipData && <Leadership data={leadershipData} />}
      {portfolioData && cardData && (
        <Solutions data={portfolioData} cardData={cardData} />
      )}
      {ourThoughtData && <OurThought data={partnersData} />}
      {aboutData && <OurStory data={aboutData} />}
      {joinTeamData && <JoinTeam data={joinTeamData} />}
      {contactData && (
        <>
          <GoogleReCaptchaProvider reCaptchaKey={REACT_RECAPTCHA_SITE_KEY}>
            {' '}
            <ContactContainer
              data={contactData}
              contactData={contactFormData}
            />
          </GoogleReCaptchaProvider>
        </>
      )}
      {footerData && <Footer data={footerData} />}
      <ScrollToTopButton />
    </div>
  );
};

export default WebsiteHomepage;
