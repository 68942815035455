import { useMemo } from "react";
import PropTypes from "prop-types";

const Property1Default6 = ({
  className = '',
  btnName,
  property1DefaultPosition,
  property1DefaultTop,
  property1DefaultLeft,
}) => {
  const property1Default3Style = useMemo(() => {
    return {
      position: property1DefaultPosition,
      top: property1DefaultTop,
      left: property1DefaultLeft,
    };
  }, [property1DefaultPosition, property1DefaultTop, property1DefaultLeft]);

  return (
    <div
      className={` mt-2 flex flex-row items-start justify-start text-left text-xl text-steelblue font-poppins ${className}`}
      style={property1Default3Style}
    >
      <div
        className={`gradient-border rounded-8xs bg-white overflow-hidden flex flex-row items-center justify-center py-[3px] px-[15px] text-left text-xl text-steelblue
       font-poppins border-[4px] border-solid border-deepskyblue-100`}
      >
        <div className="relative uppercase font-medium text-[16px]">
          {btnName}
        </div>
      </div>
    </div>
  );
};

Property1Default6.propTypes = {
  className: PropTypes.string,

  /** Style props */
  property1DefaultPosition: PropTypes.any,
  property1DefaultTop: PropTypes.any,
  property1DefaultLeft: PropTypes.any,
};

export default Property1Default6;
