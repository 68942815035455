import PropTypes from 'prop-types';
import { API_URL } from './services/config';
const Card = ({ title, description, image, className = '' }) => {
  const card_image = image.data?.[0].attributes
    ? `${API_URL}${image.data?.[0].attributes.url}`
    : '';

  return (
    <div className={`text-left  text-steelblue font-poppins p-4 ${className}`}>
      <div className="m-auto relative shadow-[0px_10px_30px_10px_rgba(8,_138,_178,_0.3)] rounded-[10px] bg-white w-[80%]  p-6 flex flex-col justify-between">
        <img
          className="w-full h-48   object-cover rounded-t-lg"
          alt={title}
          src={card_image}
        />

        <div className="p-2 flex flex-col flex-grow">
          <b className="block  text-[16px] md:text-[16px] lg:text-xl mb-2">
            {title}
          </b>
          <div
            className=" text-[14px] md:text-[16px] lg:text-[18px] break-words whitespace-normal overflow-wrap text-pretty
 "
          >
            {description}
          </div>
        </div>
      </div>
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.any,
  className: PropTypes.string,
};

export default Card;
