import Property1Default5 from './Property1Default5';

const JoinTeam = ({ data }) => {
  return (
    <div
      className="relative bg-white  py-10  bg-gradient-to-r from-[#f5feff] via-[rgba(6,174,212,0.2)] to-[rgba(54, 96, 112, 0.2)] w-full py-12 text-center"
      data-scroll-to={data?.href || 'joinTeamContainer'}
    >
      <div className="capitalize inline-block w-full max-w-xl mx-auto mb-2">
        <b className="text-[28px]   md:text-[36px]  lg:text-[36px] xl:text-[42px]">
          {data?.title}{' '}
        </b>
        <span className="text-[28px]   md:text-[36px]  lg:text-[36px] xl:text-[42px]">
          <b>{data?.subTitle}</b>
        </span>
      </div>
      <div className="text-[14px]  md:text-[16px] lg:text-[18px]  break-words leading-8 whitespace-normal overflow-wrap  justify-center text-aligh-center md:leading-6 md:leading-8  text-left  px-10 md:px-10 lg:px-16 max-w-7xl max-w-4xl mx-auto mb-8">
        <div dangerouslySetInnerHTML={{ __html: data.description }}></div>
      </div>
      {/* <div className="flex justify-center mt-8">
        <Property1Default5
          cAReers={data.button.lable}
          property1DefaultPosition="relative"
          property1DefaultWidth="90px"
        />
      </div> */}
    </div>
  );
};

export default JoinTeam;
