import { API_URL } from './services/config';

const OurThought = ({ data }) => {
  const partner_image = data.images.data.attributes
    ? `${API_URL}${data.images.data.attributes.url}`
    : '';
  return (
    <div
      className="relative  py-10 max-w-8xl  bg-white w-full   content-center  text-center  "
      data-scroll-to={data?.href || 'partnerContainer'}
    >
      <div className="relative w-full bg-gradient-to-r from-[#fff] via-[rgb(255, 255, 255)] to-[rgb(228, 228, 228)] ">
        <div className=" inset-0 flex flex-col justify-center items-center ">
          <div className="capitalize inline-block w-[90%] lg:w-[60%] mb-2">
            <b className="text-[28px]   md:text-[36px]  lg:text-[36px] xl:text-[42px] ">
              {data?.text}
            </b>{' '}
          </div>
          <div className="text-[14px]  md:text-[16px] lg:text-[18px]  break-words whitespace-normal overflow-wrap leading-8 font-medium text-center inline-block w-[80%] lg:w-[100%] max-w-7xl">
            <div dangerouslySetInnerHTML={{ __html: data.description }}></div>
          </div>
          <div className="flex justify-center px-10 ">
            <img
              className="w-full max-w-7xl h-auto object-cover squre-full mt-2"
              alt="partner-image"
              src={partner_image}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurThought;
