import axios from 'axios';
import { API_URL } from './config';
import { REACT_PUBLIC_STRAPI_API_TOKEN } from './config';
const api = axios.create({
  baseURL: API_URL,
});

api.interceptors.request.use(config => {
  const token = `Bearer ${REACT_PUBLIC_STRAPI_API_TOKEN}`;
  config.headers.Authorization = token;

  return config;
});

export default api;
