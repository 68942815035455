import { useState } from "react";
import PropTypes from "prop-types";
import { API_URL } from './services/config';

const Header = ({ className = '', navbarData }) => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const logo_img = navbarData.logo?.data
    ? `${API_URL}${navbarData.logo.data[0].attributes.url}`
    : '';

  const handleScroll = selector => {
    const anchor = document.querySelector(selector);
    if (anchor) {
      const yOffset = -80; // Adjust this value to control the offset
      const y =
        anchor.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
      setMobileMenuOpen(false); // Close mobile menu after navigation
    }
  };

  return (
    <div
      className={`fixed top-0 left-0 w-full content-center bg-white shadow-md z-50  ${className}`}
    >
      <div className=" flex justify-between  items-center max-w-5xl mx-auto px-4 py-1 ">
        <a
          href={navbarData?.hrefLogo || '/'}
          className="flex-shrink-0 items-center mt-2 md:mt-2 lg:mt-2 xl:mt-2  object-center"
        >
          <img
            className="absolute top-4 max-w-[100px]  md:max-w-32 lg:w-32  xl:w-32  object-cover"
            alt="iseehear-logo"
            src={logo_img}
          />
        </a>

        <nav className="hidden py-4 lg:flex space-x-20">
          {navbarData?.section.map((item, index) => (
            <div
              key={index}
              className="cursor-pointer text-lg"
              onClick={() => handleScroll(`[data-scroll-to='${item.href}']`)}
            >
              <span className="capitalize custom-hover-underline">
                {item.label}
              </span>
            </div>
          ))}
        </nav>
        <div className="lg:hidden flex items-center">
          <button
            className="bg-white"
            onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
          >
            <svg
              className="w-6 h-6 text-gray-800"
              fill="none"
              stroke="#0E7090"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              ></path>
            </svg>
          </button>
        </div>
      </div>
      {isMobileMenuOpen && (
        <div className="lg:hidden flex flex-col items-end bg-white shadow-md w-full px-2  ">
          {navbarData?.section.map((item, index) => (
            <div
              key={index}
              className="cursor-pointer py-2 text-base pr-8"
              onClick={() => handleScroll(`[data-scroll-to='${item.href}']`)}
            >
              <span className="capitalize custom-hover-underline">
                {item.label}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
